<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'秤具管理'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="根据秤的产品编号查询"
          v-model="searchQuery.number"
          class="searchInput"
        >
          <template slot="prepend">产品编号</template>
        </el-input>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">秤具状态</div>
          <el-select
            clearable
            v-model="searchQuery.weighStatus"
            placeholder="根据秤具状态"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">蓝牙状态</div>
          <el-select
            clearable
            v-model="searchQuery.bluetoothStatus"
            placeholder="根据秤的蓝牙状态"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/market/weighingTool",
      searchVal: "",
      jobList: [],
      searchQuery: { name: "" },
      rules: {
        number: [
          { required: true, message: "请输入产品编号", trigger: ["blur","change"] },
        ],
        deviceId: [
          { required: true, message: "请输入蓝牙ID", trigger: ["blur","change"] },
        ],
      },
      props: [
        {
          label: "产品编号",
          prop: "number",
          align: "center",
        },

        {
          label: "蓝牙ID",
          prop: "deviceId",
          align: "center",
        },
        {
          label: "秤具状态",
          prop: "weighStatus",
          align: "center",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "离线", 1: "在线" };
            return statusMap[row.weighStatus];
          },
        },
        {
          label: "蓝牙状态",
          prop: "bluetoothStatus",
          align: "center",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "离线", 1: "在线" };
            return statusMap[row.bluetoothStatus];
          },
        },
      ],
      status: [
        { label: "离线", value: "0" },
        { label: "在线", value: "1" },
      ],
      formProps: [
        {
          label: "产品编号",
          prop: "number",
          type: "input",
        },

        {
          label: "蓝牙ID",
          prop: "deviceId",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[5].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
